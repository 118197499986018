<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             @click="backAnswer"
             src="@/assets/interesing/退出@2x.png"
             alt="">
        <span> 趣味训练</span>
        <!-- <img class="data"
             src="@/assets/interesing/计时@2x.png"
             alt=""> -->
      </div>
      <div class="topRight">
        <img src="@/assets/interesing/Left@2x (1).png"
             alt=""
             @click="back()">
        <img src="@/assets/interesing/Right@2x.png"
             alt=""
             @click="next()">
        <span @click="resfresh()">刷新</span>
        <img src="@/assets/interesing/发帖@2x.png"
             alt=""
             @click="showInsDialog">
        <img src="@/assets/interesing/反馈@2x.png"
             @click="feed"
             alt="">
        <img src="@/assets/interesing/答题卡@2x.png"
             alt=""
             @click="todetail()">
      </div>
    </div>
    <div class="topic"
         v-for="(item) in topicListData"
         :key="item.answer_id"
         v-show="item.answer_id == idx">
      <div class="tips">题目：{{item.answer_id}}</div>
      <div class="score">{{item.question_type_name}}：{{item.question_score}}分，{{item.source_name}}</div>
      <div class="answer"
           v-html="item.question_content"></div>
      <div class="check"></div>
      <div class="reply">
        <div class="reprybg">
          <div class="option"
               :style="{'background':isActive == 'A'?'#2196f3':'#ffffff'}"
               @click="optionClick('A',idx)">A</div>
          <div class="option"
               :style="{'background':isActive == 'B'?'#2196f3':'#ffffff'}"
               @click="optionClick('B',idx)">B</div>
          <div class="option"
               :style="{'background':isActive == 'C'?'#2196f3':'#ffffff'}"
               @click="optionClick('C',idx)">C</div>
          <div class="option"
               :style="{'background':isActive == 'D'?'#2196f3':'#ffffff'}"
               @click="optionClick('D',idx)">D</div>
        </div>
      </div>
    </div>
    <Form ref="FormRef" />
    <FeedBack ref="FeedBackRef" />
  </div>
</template>
<script>
import { getTopicList, subTrain } from '@/api/Aaswer.js'
import Form from '@/views/interesting/components/form.vue'
import FeedBack from '@/views/interesting/components/feedback.vue'
export default {
  data () {
    return {
      topicListData: [],
      idx: 1,
      question_content: '',
      rightNum: 0,
      errorNum: 0,
      group_id: '',
      subjectId: '',
      isActive: '',
      indexFlag: '',
      subData: {},
      startTime: '',
      endTime: '',
      timeDiff: ''

    }
  },
  components: {
    Form,
    FeedBack
  },
  async created () {
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    let subjectId = Number(this.$route.query.subject_id)
    const topicList = await getTopicList({
      uid: userId,
      subject_id: subjectId
    })
    this.topicListData = topicList.data
    this.group_id = topicList.data[0].group_id
    this.subjectId = subjectId
  },
  methods: {
    feed () {
      let form = {
        category: 0,
        comment: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FeedBackRef.form = form
      this.$refs.FeedBackRef.dialogVisible = true
    },
    showInsDialog () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
    },
    resfresh () {
      this.$router.go(0)
    },
    back () {
      // console.log('idx', this.idx)
      if (this.idx > 1) {
        this.idx--
      }
    },
    async next () {
      // 在开始做题时记录开始时间
      // this.startTime = new Date().getTime();
      let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let userId = Number(userinfo.user_id)
console.log('this.subData.group_id',this.subData.group_id)
if(!this.subData.group_id){
   if (this.idx < this.topicListData.length) {
          // 在开始做题时记录开始时间
          this.startTime = new Date().getTime();
          this.isActive = ''
          this.idx++

        } else {
          this.$message.warning('已经是最后一题')
        }
}else{
await subTrain({
        group_id: Number(this.subData.group_id),
        question_id: Number(this.subData.question_id),
        answer: this.subData.choiceResult,
        uid: userId,
        use_time: Math.floor((this.endTime - this.startTime) / 1000) < 0 ? -Math.floor((this.endTime - this.startTime) / 1000) : Math.floor((this.endTime - this.startTime) / 1000)
      }).then((res) => {

        if (this.idx < this.topicListData.length) {
          // 在开始做题时记录开始时间
          this.startTime = new Date().getTime();
          this.isActive = ''
          this.idx++

        } else {
          this.$message.warning('已经是最后一题')
        }
      })

}
      


    },
    backAnswer () {

      this.$confirm('是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$message({
        //   type: 'success',
        //   // message: '删除成功!'
        // });
        this.$router.push('/interesting')
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    todetail () {
      this.$router.push({
        path: '/detail',
        query: {
          group_id: this.group_id,
          subjectId: this.subjectId,
          rightNum: this.rightNum,
          errorNum: this.errorNum,
          topicListData: this.topicListData
        }
      })
    },
    optionClick (option, idx) {
      if (this.startTime == '') {
        this.startTime = new Date().getTime();
      }
      this.endTime = new Date().getTime()
      const item = this.topicListData[idx - 1];
      this.indexFlag = this.topicListData[idx - 1]
      // console.log('idx', idx)
      // console.log('option', option)
      this.isActive = option
      item.choiceResult = option;

      this.subData = item
      // console.log('this.isActive ', this.isActive)
      if (item.question_answer == option) {
        item.isTrue = true;
        this.rightNum++;
      } else {
        item.isTrue = false;
        this.errorNum++;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  cursor: pointer;
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;

      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      .data {
        width: 32rem;
        height: 32rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
    .topRight {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 48rem;
        height: 48rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
        margin-left: 24rem;
      }
    }
  }
  .topic {
    padding: 40rem 40rem 32rem 40rem;
    box-sizing: border-box;
    .tips {
      font-size: 28rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42rem;
      margin-bottom: 8rem;
    }
    .score {
      font-size: 24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36rem;
      margin-bottom: 32rem;
    }
    .answer {
      font-size: 32rem;
      height: 432rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 32rem;
    }
    .check {
      height: 240rem;
      margin-bottom: 32rem;
    }
    .reply {
      display: flex;
      height: 152rem;
      justify-content: space-between;
      color: #333333;
      background-color: #f5f5f5;
      padding: 8rem;
      box-sizing: border-box;
      .reprybg {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .option {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid #f5f5f5;
          align-items: center;
          font-size: 32rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>